import React, { useEffect, useState } from 'react';
import Sheet from '@mui/joy/Sheet';
import Box from '@mui/joy/Box';
import List from '@mui/joy/List';
import ListSubheader from '@mui/joy/ListSubheader';
import ListItem from '@mui/joy/ListItem';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemButton from '@mui/joy/ListItemButton';
import Typography from '@mui/joy/Typography';
import Divider from '@mui/joy/Divider';
import { closeSidebar } from '../../../utils/closeSidebar';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import css from './Sidebar.module.css';
import { getUser, logOut } from '../../../redux/auth/operations';
import { useDispatch, useSelector } from 'react-redux';
import { Button, IconButton, Modal } from '@mui/joy';
import { AppDispatch } from '../../../redux/store';
import useWidth from '../../../hooks/useWidth';
import { useTranslation } from 'react-i18next';
import { getConstants, withdrawalBalance } from '../../../services/operators';
import { getGlobalUser } from '../../../redux/auth/selectors';
import { socket } from '../../../socket';
import { updateBalance } from '../../../redux/auth/actions';
import Logo from '../../Logo/Logo';
import IconComponent from '../../IconComponents/IconComponents';
import { boxStyle, triangleStyle } from './styles.js';

const Sidebar = ({ onSidebarClick }: { onSidebarClick: () => void; }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useSelector(getGlobalUser);
  const [openWithdrawal, setOpenWithdrawal] = useState(false);
  const [withdrawal, setWithdrawal] = useState(false);
  const [validation, setValidation] = useState(false);
  const [amountWithdrawal, setAmountWithdrawal] = useState('');
  const { isMobile } = useWidth();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [showPayin, setShowPayin] = useState(false);
  const [showPayinQAC, setShowPayinQAC] = useState(false);
  const [showPayOut, setShowPayOut] = useState(false);
  const [hideMenu, setHideMenu] = useState(true);
  const [show, setShow] = useState(false);
  const [showTitle, setShowTitle] = useState(false);
  const [showPayIn, setShowPayIn] = useState(false);
  const [showPayInQAC, setShowPayInQAC] = useState(false);
  const [showPayOutTitle, setShowPayOutTitle] = useState(false);
  const [minAmountWithdrawal, setMinAmountWithdrawal] = useState('');
  const handleButtonClick = () => {
    setShowPayin(!showPayin);
  };

  const openPayinQAC = () => {
    setShowPayinQAC(!showPayinQAC);
  };
  const openPayOut = () => {
    setShowPayOut(!showPayOut);
  };

  const toggleMenu = () => {
    setHideMenu(!hideMenu);
    onSidebarClick();
  };
  useEffect(() => {
    dispatch(getUser());
    socket.on('merchantBalance', data => {
      dispatch(updateBalance(data.balance));
    });
    return () => {
      socket.off('merchantBalance');
    };
  }, []);

  const activeClassName = ({ isActive }: { isActive: boolean }) =>
    isActive ? `${css.active}` : `${css.navLink}`;

  const handleSignOut = () => {
    closeSidebar();
    logOut();
  };

  const toggleOpenWithdrawal = () => {
    setOpenWithdrawal(!openWithdrawal);
  };

  const handleChangeWithdrawal = (value: string) => {
    setAmountWithdrawal(value);
  };

  useEffect(() => {
    const fetch = async () => {
      const resp = await getConstants();
      if (resp) {
         setMinAmountWithdrawal(resp.MIN_WITHDRAWAL_AMOUNT)
      }
    }
    fetch();
  }, []);

  const handleWithdrawal = async () => {
    if (Number(amountWithdrawal) < Number(minAmountWithdrawal) || Number(amountWithdrawal) > Number(user.balance)) {
      setValidation(true);
      return;
    } else {
      setValidation(false);
      const withdraw = await withdrawalBalance(Number(amountWithdrawal));

      if (withdraw) {
        setWithdrawal(true);
        navigate('/withdraw-history');
      }
    }
  };

  return (
    <React.Fragment>
      <Box
        className="SecondSidebar-overlay"
        id="sidebarID"
        sx={{
          position: 'fixed',
          zIndex: 20,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          opacity: 'var(--SideNavigation-slideIn)',
          backgroundColor: 'var(--joy-palette-background-backdrop)',
          transition: 'opacity 0.4s',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--FirstSidebar-width, 0px)))',
            lg: 'translateX(-100%)',
          },
        }}
        onClick={() => closeSidebar()}
      />

      <Sheet
        className="SecondSidebar"
        color="neutral"
        sx={{
          position: {
            xs: 'fixed',
            lg: 'sticky',
          },
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1.1) + var(--SideNavigation-slideIn, 0) * var(--FirstSidebar-width, 0px)))',
            lg: 'none',
          },
          transition: 'transform 0.4s',
          zIndex: 21,
          width: hideMenu ? '260px' : '150px',
          height: '100vh',
          // height: '100%',
          top: 0,
          p: 2,
          flexShrink: 0,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          borderRight: '1px solid',
          borderColor: 'divider',
          // overflowY:  'scroll' ,
          overflowY: hideMenu ? 'scroll' : '',
          '&::-webkit-scrollbar': {
            width: '2px',
          },
          '&:hover::-webkit-scrollbar-thumb': {
            backgroundColor: 'transparent',
          },

        }}
      >
        <Box sx={{ display: 'flex', justifyContent: hideMenu ? 'space-between' : 'center' }}>
          {hideMenu && <Logo />}
          <IconButton onClick={toggleMenu}>
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" fill="none"
              style={{ transform: !hideMenu ? 'rotate(180deg)' : 'rotate(0deg)' }}
            >
              <path
                fill="#868E96"
                d="M14.7 14.3a1 1 0 0 1-1.4 1.4l-7-7a1 1 0 0 1 0-1.4l7-7a1 1 0 1 1 1.4 1.4L8.4 8l6.3 6.3ZM0 15a1 1 0 1 0 2 0V1a1 1 0 0 0-2 0v14Z"
              />
            </svg>
          </IconButton>
        </Box>

        <List
          size="sm"
          sx={{
            '--ListItem-radius': '6px',
            '--List-gap': '6px',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: hideMenu ? 'flex-start' : 'center' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="22" fill="none">
              <path
                fill="#868E96"
                d="M2 11v4a1.5 1.5 0 1 0 3 0v-4a1.5 1.5 0 1 0-3 0Zm6 0v4a1.5 1.5 0 1 0 3 0v-4a1.5 1.5 0 1 0-3 0ZM1.5 21.5h16a1.5 1.5 0 1 0 0-3h-16a1.5 1.5 0 1 0 0 3ZM14 11v4a1.5 1.5 0 1 0 3 0v-4a1.5 1.5 0 1 0-3 0ZM8.6 1l-8 4.2c-.3.2-.6.6-.6 1 0 .7.6 1.3 1.3 1.3h16.5a1.2 1.2 0 0 0 .5-2.3L10.4 1a2 2 0 0 0-1.8 0Z"
              />
            </svg>
            {hideMenu && (
              <Typography sx={{ ml: '4px', fontWeight: 500, fontSize: '18px', color: '#868E96' }}>
                {t('sidebarBalance')}:
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexWrap: hideMenu ? 'nowrap' : 'wrap',
              flexDirection: hideMenu ? 'row' : 'column',
              alignItems: 'center',
              mt: hideMenu ? 0 : 1,
            }}
          >
            <Typography sx={{ fontWeight: 600, fontSize: hideMenu ? '18px' : '16px', color: '#F1F1F1' }}>
              {Number(user.balance).toFixed(2)}{' '}
            </Typography>
            <Typography sx={{ fontWeight: 600, fontSize: hideMenu ? '18px' : '16px', color: '#F1F1F1', ml: hideMenu ? 1 : 0}}>
              {' '}
              USDT
            </Typography>
          </Box>

          {hideMenu && (
            <Button
              sx={{
                background:
                  'linear-gradient(135deg, rgba(104, 79, 221, 1) 0%, rgba(47, 146, 172, 1) 100%)',
                width: '100%',
                color: '#F1F1F1',
                padding: '10px',
                fontSize: '14px',
                '&:hover': {
                  background:
                    'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                },
                mt: 2,
              }}
              onClick={toggleOpenWithdrawal}
            >
              {t('sidebar_btn_withdrawal')}
            </Button>
          )}
          <ListItem>
            <ListItemButton
              onClick={() => closeSidebar()}
              selected={pathname === '/dashboard'}
              onMouseOver={() => setShowTitle(true)}
              onMouseOut={() => setShowTitle(false)}
     
              sx={{
                '&.Mui-selected': {
                  backgroundColor: 'transparent',
                },
                '&.Mui-focusVisible': {
                  backgroundColor: '#343A40',
                },
                ':hover': {
                  backgroundColor: '#343A40',
                },
              }}
            >
              <ListItemContent>
                <NavLink
                  className={activeClassName}
                  to="dashboard"
                  style={{
                    padding: '12px 0',
                    display: 'flex',
                    color: '#CED4DA',
                    justifyContent: hideMenu ? 'flex-start' : 'center',
                    position: 'relative',
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none">
                    <path
                      fill="#ADB5BD"
                      d="M18.3 10c-.4 0-.8-.2-1.2-.5-.3-.3-.4-.7-.4-1.2V1.7c0-.5.1-.9.4-1.2.4-.3.8-.5 1.2-.5h10c.5 0 .9.2 1.2.5.3.3.5.7.5 1.2v6.6c0 .5-.2.9-.5 1.2-.3.3-.7.5-1.2.5h-10ZM1.7 16.7c-.5 0-.9-.2-1.2-.5-.3-.3-.5-.7-.5-1.2V1.7C0 1.2.2.8.5.5.8.2 1.2 0 1.7 0h10c.4 0 .8.2 1.2.5.3.3.4.7.4 1.2V15c0 .5-.1.9-.4 1.2-.4.3-.8.5-1.2.5h-10ZM18.3 30c-.4 0-.8-.2-1.2-.5-.3-.3-.4-.7-.4-1.2V15c0-.5.1-.9.4-1.2.4-.3.8-.5 1.2-.5h10c.5 0 .9.2 1.2.5.3.3.5.7.5 1.2v13.3c0 .5-.2.9-.5 1.2-.3.3-.7.5-1.2.5h-10ZM1.7 30c-.5 0-.9-.2-1.2-.5-.3-.3-.5-.7-.5-1.2v-6.6c0-.5.2-.9.5-1.2.3-.3.7-.5 1.2-.5h10c.4 0 .8.2 1.2.5.3.3.4.7.4 1.2v6.6c0 .5-.1.9-.4 1.2-.4.3-.8.5-1.2.5h-10Z"
                    />
                  </svg>
                  {hideMenu && <Typography sx={{ ml: 1, fontSize: '16px', fontWeight: 600, }}>{t('sidebar_nav_dashboard')}</Typography>}
                  {!hideMenu && showTitle && (
                    <Box sx={boxStyle}>
                      <Typography sx={{ fontWeight: 600 }}>
                        {t('sidebar_nav_dashboard')}
                      </Typography>
                      <Box sx={triangleStyle}></Box>
                    </Box>
                  )}
                </NavLink>     
              </ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              onClick={() => closeSidebar()}
              selected={pathname === '/settings'}
              onMouseOver={() => setShow(true)}
              onMouseOut={() => setShow(false)}
              sx={{
                '&.Mui-selected': {
                  backgroundColor: 'transparent',
                },
                '&.Mui-focusVisible': {
                  backgroundColor: '#343A40',
                },
                ':hover': {
                  backgroundColor: '#343A40',
                },
              }}
            >
              <ListItemContent>
                <NavLink
                  className={activeClassName}
                  to="settings"
                  style={{
                    padding: '12px 0',
                    display: 'flex',
                    color: '#CED4DA',
                    justifyContent: hideMenu ? 'flex-start' : 'center',
                    position: 'relative',
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none">
                    <path
                      fill="#ADB5BD"
                      d="M15 16.7c1.6 0 3-.6 4.1-1.7a5.6 5.6 0 0 0 1.7-4.2c0-1.6-.5-3-1.7-4A5.6 5.6 0 0 0 15 5c-1.6 0-3 .6-4.1 1.7a5.6 5.6 0 0 0-1.7 4.1c0 1.6.5 3 1.7 4.2a5.6 5.6 0 0 0 4.1 1.7ZM3.3 30c-.9 0-1.7-.3-2.3-1-.7-.6-1-1.4-1-2.3V3.3C0 2.4.3 1.6 1 1c.6-.7 1.4-1 2.3-1h23.4c.9 0 1.7.3 2.3 1 .7.6 1 1.4 1 2.3v23.4c0 .9-.3 1.7-1 2.3-.6.7-1.4 1-2.3 1H3.3Zm0-3.3h23.4v-2a16.8 16.8 0 0 0-18.1-3.4c-2 .8-3.8 2-5.3 3.4v2Z"
                    />
                  </svg>
                  {hideMenu && <Typography sx={{ ml: 1, fontSize: '16px', fontWeight: 600, }}>{t('sidebarAccaunt')}</Typography>}

                  {!hideMenu && show && (
                    <Box sx={boxStyle}>
                      <Typography sx={{ fontWeight: 600 }}>
                        {t('sidebarAccaunt')}
                      </Typography>
                      <Box sx={triangleStyle}></Box>
                    </Box>
                  )}
                </NavLink>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
          <Divider />
          {hideMenu && (
            <ListSubheader sx={{ fontWeight: 500, color: '#868E96', fontSize: '16px', mt: 2 }}>
              {t('sidebarNavPayment')}
            </ListSubheader>
          )}

          <ListItem sx={{ display: 'block' }}>
            <ListItemButton
              onClick={handleButtonClick}
              onMouseOver={() => setShowPayIn(true)}
              onMouseOut={() => setShowPayIn(false)}
              sx={{
                padding: '16px 10px',
                '&.Mui-selected': {
                  backgroundColor: 'transparent',
                },
                '&.Mui-focusVisible': {
                  backgroundColor: '#343A40',
                },
                ':hover': {
                  backgroundColor: '#343A40',
                },
                position: 'relative',
              }}
            >
              <ListItemContent
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >

                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" fill="none">
                  <path
                    fill="#ADB5BD"
                    d="M20.3.3a13.3 13.3 0 0 1 5.5 25.5A13.3 13.3 0 1 1 8.2 8.2 13.3 13.3 0 0 1 20.3.3Zm-5 11.7H12v1.7a4.2 4.2 0 0 0-.3 8.3h3.8a.8.8 0 0 1 0 1.7H8.7V27H12v1.7h3.3V27a4.2 4.2 0 0 0 .3-8.3H12a.8.8 0 0 1 0-1.7h6.8v-3.3h-3.4V12Zm5-8.3A10 10 0 0 0 13 7a13.3 13.3 0 0 1 14 14.1 10 10 0 0 0-6.6-17.4Z"
                  />
                </svg>
                {hideMenu && (
                  <>
                    <Typography sx={{ color: '#CED4DA', fontSize: '16px', fontWeight: 600, ml: 1 }}>
                      {t('sideBarPayInP2P')}
                    </Typography>
                    <Box
                      style={{
                        transform: showPayin ? 'rotate(-180deg)' : 'rotate(0deg)',
                        marginLeft: 'auto',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <IconComponent paths="M3.7 7.8a1 1 0 1 0-1.4 1.4l9 8.5a1 1 0 0 0 1.4 0l9-8.5a1 1 0 1 0-1.4-1.4L12 15.6 3.7 7.8Z" />
                    </Box>
                  </>
                )}
                {!hideMenu && showPayIn && (
                  <Box sx={boxStyle}>
                    <Typography sx={{ fontWeight: 600 }}>
                      {t('sideBarPayInP2P')}
                    </Typography>
                    <Box sx={triangleStyle}></Box>
                  </Box>
                )}

              </ListItemContent>
            </ListItemButton>
            {showPayin && (
              <Box sx={{ display: 'block' }}>
                  <ListItemButton
                    onClick={() => closeSidebar()}
                    selected={pathname === '/transactions'}
                  >
                    <ListItemContent>
                      <NavLink
                        className={activeClassName}
                        to="transactions"
                        style={{ color: '#CED4DA', fontWeight: 500, fontSize: hideMenu ? '16px' : '14px', }}
                      >
                        {t('sidebar_nav_history')}
                      </NavLink>
                    </ListItemContent>
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => closeSidebar()}
                    selected={pathname === '/withdraw-history'}
                  >
                    <ListItemContent>
                      <NavLink
                        className={activeClassName}
                        to="withdraw-history"
                        style={{ color: '#CED4DA', fontWeight: 500, fontSize: hideMenu ? '16px' : '14px', }}
                      >
                        {t('withdraw_history')}
                      </NavLink>
                    </ListItemContent>
                  </ListItemButton>
              </Box>
            )}
          </ListItem>

          <ListItem sx={{ display: 'block' }}>
            <ListItemButton
              onClick={openPayinQAC}
              onMouseOver={() => setShowPayInQAC(true)}
              onMouseOut={() => setShowPayInQAC(false)}
              sx={{
                padding: '16px 10px',
                '&.Mui-selected': {
                  backgroundColor: 'transparent',
                },
                '&.Mui-focusVisible': {
                  backgroundColor: '#343A40',
                },
                ':hover': {
                  backgroundColor: '#343A40',
                },
                position: 'relative',
              }}
            >
              <ListItemContent sx={{ display: 'flex', justifyContent: 'center', }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none">
                  <path
                    fill="#ADB5BD"
                    d="M15 13.3a26 26 0 0 0 10.6-2c3-1.2 4.4-2.8 4.4-4.6 0-1.9-1.5-3.4-4.4-4.7A26 26 0 0 0 15 0 26 26 0 0 0 4.4 2C1.4 3.3 0 4.8 0 6.7c0 1.8 1.5 3.4 4.4 4.7 2.9 1.3 6.4 2 10.6 2Zm0 4.2a32.2 32.2 0 0 0 9.2-1.5c1.6-.5 3-1.2 4-2 1.2-1 1.8-2 1.8-3.2V15c0 1.2-.6 2.3-1.7 3.1-1.1.9-2.5 1.5-4 2a27.5 27.5 0 0 1-9.3 1.6 32.1 32.1 0 0 1-9.2-1.5c-1.6-.6-3-1.2-4-2-1.2-1-1.8-2-1.8-3.2v-4.2c0 1.3.6 2.3 1.7 3.1 1.1.9 2.5 1.6 4 2.1a27.5 27.5 0 0 0 9.3 1.5Zm0 8.3a32.2 32.2 0 0 0 9.2-1.5c1.6-.5 3-1.2 4-2 1.2-.9 1.8-2 1.8-3.1v4.1c0 1.3-.6 2.3-1.7 3.1-1.1.9-2.5 1.6-4 2.1A27.5 27.5 0 0 1 15 30a32.1 32.1 0 0 1-9.2-1.5c-1.6-.5-3-1.2-4-2-1.2-1-1.8-2-1.8-3.2v-4.1c0 1.2.6 2.2 1.7 3 1.1 1 2.5 1.6 4 2.1a27.5 27.5 0 0 0 9.3 1.5Z"
                  />
                </svg>

                {hideMenu && (
                  <>
                    <Typography
                      variant="plain"
                      sx={{ color: '#CED4DA', fontWeight: 600, ml: 1, fontSize:'16px' }}
                    >
                      {t('sideBarPayInQAC')}
                    </Typography>
                    <Box
                      style={{
                        transform: showPayinQAC ? 'rotate(-180deg)' : 'rotate(0deg)',
                        marginLeft: 'auto',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <IconComponent paths="M3.7 7.8a1 1 0 1 0-1.4 1.4l9 8.5a1 1 0 0 0 1.4 0l9-8.5a1 1 0 1 0-1.4-1.4L12 15.6 3.7 7.8Z" />
                    </Box>
                  </>
                )}
                {!hideMenu && showPayInQAC && (
                  <Box sx={boxStyle}>
                    <Typography sx={{ fontWeight: 600  }}>
                      {t('sideBarPayInQAC')}
                    </Typography>
                    <Box sx={triangleStyle}></Box>
                  </Box>
                )}
              </ListItemContent>
            </ListItemButton>

            {showPayinQAC && (
              <Box sx={{ display: 'block' }}>
                  <ListItemButton
                    onClick={() => closeSidebar()}
                    selected={pathname === '/transactions-qac'}
                  >
                    <ListItemContent>
                      <NavLink
                        className={activeClassName}
                        to="transactions-qac"
                        style={{ color: '#CED4DA', fontWeight: 500, fontSize:hideMenu ? '16px' :'14px',}}
                      >
                        {t('sidebarTRHistoryQAC')}
                      </NavLink>
                    </ListItemContent>
                  </ListItemButton>
              </Box>
            )}
          </ListItem>
          <ListItem sx={{ display: 'block' }}>
            <ListItemButton
              onClick={openPayOut}
              // selected={pathname === '/dashboard'}
              onMouseOver={() => setShowPayOutTitle(true)}
              onMouseOut={() => setShowPayOutTitle(false)}
              sx={{
                padding: '16px 10px',
                '&.Mui-selected': {
                  backgroundColor: 'transparent',
                },
                '&.Mui-focusVisible': {
                  backgroundColor: '#343A40',
                },
                ':hover': {
                  backgroundColor: '#343A40',
                },
              }}
            >
              <ListItemContent sx={{ display: 'flex', justifyContent: 'center' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="30" fill="none">
                  <path
                    fill="#ADB5BD"
                    d="M3.3 30c-.9 0-1.7-.3-2.3-1-.7-.6-1-1.4-1-2.3V3.3C0 2.4.3 1.6 1 1c.6-.7 1.4-1 2.3-1h23.4c.9 0 1.7.3 2.3 1 .7.6 1 1.4 1 2.3H16.7c-2 0-3.6.7-4.8 1.9A6.5 6.5 0 0 0 10 10v10c0 2 .6 3.6 1.9 4.8a6.5 6.5 0 0 0 4.8 1.9H30c0 .9-.3 1.7-1 2.3-.6.7-1.4 1-2.3 1H3.3Zm13.4-6.7c-1 0-1.7-.3-2.4-1-.6-.6-1-1.4-1-2.3V10c0-1 .4-1.7 1-2.4.7-.6 1.5-1 2.4-1h11.6c1 0 1.7.4 2.4 1 .6.7 1 1.5 1 2.4v10c0 1-.4 1.7-1 2.4-.7.6-1.4 1-2.4 1H16.7Zm5-5.8a2.4 2.4 0 0 0 2.5-2.5 2.4 2.4 0 0 0-2.5-2.5 2.4 2.4 0 0 0-2.5 2.5 2.4 2.4 0 0 0 2.5 2.5Z"
                  />
                </svg>
                {hideMenu && (
                  <>
                    <Typography
                      variant="plain"
                      sx={{ color: '#CED4DA', fontSize: '16px', fontWeight: 600, ml: 1 }}
                    >
                      {t('sideBarPayOut')}
                    </Typography>
                    <Box
                      style={{
                        transform: showPayOut ? 'rotate(-180deg)' : 'rotate(0deg)',
                        marginLeft: 'auto',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <IconComponent paths="M3.7 7.8a1 1 0 1 0-1.4 1.4l9 8.5a1 1 0 0 0 1.4 0l9-8.5a1 1 0 1 0-1.4-1.4L12 15.6 3.7 7.8Z" />
                    </Box>
                  </>
                )}
                {!hideMenu && showPayOutTitle && (
                  <Box sx={boxStyle}>
                    <Typography sx={{ fontWeight: 600 }}>
                      {t('sideBarPayOut')}
                    </Typography>
                    <Box sx={triangleStyle}></Box>
                  </Box>
                )}
              </ListItemContent>
            </ListItemButton>
            {showPayOut && (
              <Box sx={{ display: 'block' }}>
                <ListItemButton
                  onClick={() => closeSidebar()}
                  selected={pathname === '/payout-history'}
                >
                  <ListItemContent>
                    <NavLink
                      className={activeClassName}
                      to="payout-history"
                      style={{ color: '#CED4DA', fontWeight: 500, fontSize:hideMenu ? '16px' :'14px',}}
                    >
                      {t('sidebarPayoutHistory')}
                    </NavLink>
                  </ListItemContent>
                </ListItemButton>
              </Box>
            )}
          </ListItem>
          <ListItem sx={{ marginTop: '40px' }}>
            <ListItemButton onClick={handleSignOut}>
              <ListItemContent
                sx={{ display: 'flex', justifyContent: 'center', alignContent: 'baseline', }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" fill="none">
                  <path
                    fill="#F1F1F1"
                    d="m3.8 7.4 3 3 .2.8c0 .2 0 .5-.3.7a1 1 0 0 1-.7.3c-.2 0-.5 0-.7-.3L.7 7.1a1 1 0 0 1-.2-.3V6l.2-.4L5.3 1c.2-.2.4-.3.7-.3.3 0 .5 0 .7.3.2.2.3.4.3.7 0 .3-.1.5-.3.7l-2.9 3H13c.6 0 1 .2 1.4.6.4.5.6 1 .6 1.5v9.4c0 .3-.1.5-.3.7a1 1 0 0 1-.7.3 1 1 0 0 1-.7-.3 1 1 0 0 1-.3-.7V7.4H3.8Z"
                  />
                </svg>
                {hideMenu &&(<Typography sx={{ color: '#F1F1F1', fontWeight: 600, ml: 1 }}>
                  {t('sidebar_exit')}
                </Typography>)}            
              </ListItemContent>
            </ListItemButton>
          </ListItem>
        </List>
      </Sheet>
      <Modal
        open={openWithdrawal}
        onClose={toggleOpenWithdrawal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Sheet
          variant="outlined"
          sx={{
            width: (isMobile && '80%') || '40%',
            borderRadius: 'md',
            p: 3,
            boxShadow: '4px 2px 64px 0 rgba(69, 69, 69, 0.2)',
            display: 'flex',
            flexDirection: 'column',
            border: 'transparent',
          }}
        >
          <IconButton onClick={toggleOpenWithdrawal} sx={{ marginLeft: 'auto' }}>
            <IconComponent paths="m10 11.9 7.5 7.5a1.3 1.3 0 0 0 2-1.9L11.8 10l7.5-7.5a1.3 1.3 0 0 0-1.9-2L10 8.2 2.5.6a1.3 1.3 0 0 0-2 1.9L8.2 10 .6 17.5a1.3 1.3 0 1 0 1.9 2l7.5-7.6Z" />
          </IconButton>
          {(!withdrawal && (
            <>
              <Typography level="body-lg" sx={{ textAlign: 'center', mb: 2, color:'#F1F1F1' }}>
                {t('sidebar_withdrawal_header')}
              </Typography>
              <input
                value={amountWithdrawal}
                onChange={(e: { target: { value: string } }) =>
                  handleChangeWithdrawal(e.target.value)
                }
                id="amountWithdrawal"
                name="amountWithdrawal"
                type="number"
                min={1}
                placeholder={t('sidebar_modal_placeholder')}
                className={css.input}
                autoComplete="amount"
                autoFocus
              />
              {validation && (
                <Typography color="danger" level="body-sm" sx={{ mb: 2 }}>
                  {t('validation_withdraw', {minAmount: minAmountWithdrawal })}
                </Typography>
              )}
              <Box sx={{ display: 'flex', gap: '8px', justifyContent: 'center' }}>
                <Button onClick={handleWithdrawal} sx={{
                  width: '300px',
                  padding: '8px',
                  background: 
                     'linear-gradient(135deg, rgba(104, 79, 221, 1) 0%, rgba(47, 146, 172, 1) 100%)',
                  color: '#F1F1F1',
                  fontWeight: 600,
                  fontSize: '16px',
                  '&:hover': {
                    background:
                      'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                  },
                }} >
                  {t('active_table_confirm')}
                </Button>

              </Box>
            </>
          )) || <Typography level="body-lg" sx={{ color: '#F1F1F1' }}>{t('confirmed_withdrawal')} </Typography>}
        </Sheet>
      </Modal>
    </React.Fragment>
  );
};
export default Sidebar;
