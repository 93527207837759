import { Table, Typography } from '@mui/joy';
import React from 'react';
import { Payouts } from '../../utils/types';
import { useTranslation } from 'react-i18next';
import HistoryItemPayout from './HistoryItemPayout';

const PayoutHistory = ({ payouts }: { payouts: Payouts[] }) => {
    const { t } = useTranslation();
    return (
        <>
            <Table
                aria-labelledby="tableTitle"
                stickyHeader
                hoverRow
                sx={{
                    '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                    '--Table-headerUnderlineThickness': '1px',
                    '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                    '--TableCell-paddingY': '4px',
                    '--TableCell-paddingX': '8px',
                }}
            >
                <thead>
                    <tr>
                        <th style={{ color:'#F1F1F1' }}>{t('history_table_priceUah')}</th>
                        <th style={{ color:'#F1F1F1' }}>{t('history_table_priceUsdt')}</th>
                        <th style={{ color:"#F1F1F1"}}>{t('history_table_startDate')}</th>
                        <th style={{ color:"#F1F1F1"}}>{t('history_table_endDate')}</th>
                        <th style={{ color:'#F1F1F1' }}>{t('history_table_status')}</th>
                        <th style={{ color:'#F1F1F1' }}>{t('history_table_actions')}</th>
                    </tr>
                </thead>
                <tbody>
                    {payouts &&
                      payouts?.map((payout: Payouts) => (
                            <HistoryItemPayout
                                key={payout.id}
                                payout={payout}
                            />
                        ))}
                </tbody>
            </Table>
            {!payouts && <Typography sx={{ mt: 2, pl: 2 }}>{t('not_found')}</Typography>}
        </>
    );
};
export default PayoutHistory;