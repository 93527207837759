import { Box } from '@mui/joy';
import React, { useEffect, useState } from 'react';
import { Payouts } from '../../../utils/types';
import HistoryItemMob from './HistoryItemPayoutMob';

const ListMobilePayout = ({
  list,
}: {
  list: Payouts[];
}) => {
  const [listPayouts, setListPayouts] = useState(list);

  useEffect(() => {
    setListPayouts(list);
  }, [list]);


  return (
    <Box sx={{ display: 'block' }}>
      {listPayouts?.map((notification: Payouts) => (
        <HistoryItemMob listItem={notification} key={notification.id} />
      ))}
    </Box>
  );
};

export default ListMobilePayout;
