import { Box, Button, Typography } from '@mui/joy';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../../../utils/dateFormater';
import { maskCreditCardNumber } from '../../../utils/formatCreditCard';
import { getStatusTextColor } from '../../../utils/getStatusColor';
import { Transactions } from '../../../utils/types';
import HistoryItemModal from './HistoryItemModal';

const HistoryItem = ({
  notification,
}: {
  notification: Transactions;
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const toggleDrawer = (inOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setOpen(inOpen);
  };
  return (
    <>
      <tr key={notification.id}>
        <td>
          <Typography level='body-xs'>
            {maskCreditCardNumber(notification.pocket_address)}
          </Typography>
        </td>
        <td>
          <Typography level='body-xs'>
            {notification.merchant_bank_title === 'iban' ? 'IBAN' : 'P2P'}
          </Typography>
        </td>
        <td>
          <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <Typography level='body-md' sx={{ fontWeight: 500, color: '#F1F1F1' }}>
              {parseFloat(notification.confirmed_amount_uah).toFixed(2)}
            </Typography>
          </Box>
        </td>
        <td>
          <Typography level='body-md' sx={{
            fontWeight: 500,
            color: '#F1F1F1',
          }}>{Number(notification.merchantAmount).toFixed(2)}</Typography>
        </td>
        <td>
          <Typography level='body-md' sx={{ color: '#F1F1F1' }}>{formatDateTime(notification.createdAt)}</Typography>
        </td>
        <td>
          <Typography level='body-md' sx={{ color: '#F1F1F1' }}>{formatDateTime(notification.updatedAt)}</Typography>
        </td>
        <td>
          <Typography level='body-md' sx={{ color: getStatusTextColor(notification?.status) }}>
            {notification?.status &&
              notification.status.charAt(0).toUpperCase() +
              notification.status.slice(1).toLowerCase()}
          </Typography>
        </td>
        <td>
          <Button
            variant='plain'
            sx={{
              display: 'block',
              m: 0,
              borderBottom: '1px solid #947EFE',
              color: '#947EFE',
              borderRadius: 0,
              p: 0,
            }}
            onClick={toggleDrawer(true)}
          >
            {t('history_table_details')}
          </Button>
        </td>
      </tr>
      <HistoryItemModal
        id={notification.id}
        open={open}
        onClose={toggleDrawer(false)}
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      />
    </>
  );
};
export default HistoryItem;
