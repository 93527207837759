import { useEffect, useState } from 'react';
import { getAllPayouts } from '../services/operators';
import { getPageSizes} from '../utils/pagination';
import { Payouts } from '../utils/types';
export const usePayouts = ({
  filterQueries,
}: {
  filterQueries: {
    searchQuery: string;
    searchStatus: string | null;
    sDate: null | Date | string;
    fDate: null | Date | string;
    sort: null | string;
  };
}) => {
  const [payouts, setPayouts] = useState<Payouts[] | []>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [count, setCount] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number | null>(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const data = await getPageSizes();
        setPageSize(data);
      } catch (error) {
        console.error(error);
      }
    };
    getData();
  }, []);

  const pageCount = Math.ceil(count / (pageSize || 10));

  const handlePageClick = ({ selected }: { selected: number }) => {
    setCurrentPage(selected + 1);
  };

  useEffect(() => {
    if (pageSize === null) {
      return;
    }
    const fetch = async () => {
      const payouts = await getAllPayouts(currentPage, pageSize, filterQueries);
      const filteredNotifications = payouts?.payouts;
      setPayouts(filteredNotifications);
      setCount(payouts?.count);
    };
    fetch();
  }, [currentPage, filterQueries, pageSize]);


  return { payouts, count, handlePageClick, pageCount };
};
