import { Box, Divider, Stack, Typography } from '@mui/joy';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useWidth from '../../../hooks/useWidth';
import { OtherStats } from '../../../utils/types';
import { formatDateText } from '../../../utils/datePickerFormat';

const Statistics = ({
  otherStats,
  filterQueries,
}: {
  otherStats: OtherStats | null;
  filterQueries: {
    sDate: string;
    fDate: string;
  };
}) => {
  const { isMobile, isLargeDesktop } = useWidth();
  const { t } = useTranslation();
  console.log(otherStats);
  return (
    <Box
      sx={{
        backgroundColor: '#111317',
        borderRadius: '16px',
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '16px 24px',
          backgroundColor: '#18191A',
          borderRadius: '16px 16px 0 0',
          width: !isLargeDesktop ? '100%' : '500px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: isMobile ? 'wrap' : 'nowrap',
          }}
        >
          <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' fill='none'>
            <path
              fill='url(#a)'
              d='M12 30h2.5v-8.5H12V30Zm5.5 0h2.5v-14h-2.5V30Zm5.5 0h2.5V20h-2.5v10Zm5.5 0H31v-4.5h-2.5V30Zm-20 0V10c0-.55-.45-1-1-1s-1 .45-1 1v20c0 .55.45 1 1 1s1-.45 1-1ZM31 10v20c0 .55.45 1 1 1s1-.45 1-1V10c0-.55-.45-1-1-1s-1 .45-1 1Z'
            />
            <defs>
              <linearGradient
                id='a'
                x1='6.9'
                x2='33.4'
                y1='22'
                y2='22'
                gradientUnits='userSpaceOnUse'
              >
                <stop stopColor='#684FDD' />
                <stop offset='1' stopColor='#2F92AC' />
              </linearGradient>
            </defs>
          </svg>
          <Typography
            level='h4'
            sx={{ color: '#F1F1F1', fontSize: isMobile ? '16px' : '20px', fontWeight: 600 }}
          >
            {t('dashboard_statistics_header')}
          </Typography>
        </Box>

        <Typography
          sx={{ color: '#CED4DA', fontSize: isMobile ? '14px' : '16px', fontWeight: 400 }}
        >
          {(filterQueries.sDate &&
              filterQueries.fDate &&
              `${formatDateText(filterQueries.sDate)} - ${formatDateText(filterQueries.fDate)}`) ||
            t('search_period')}{' '}
        </Typography>
      </Box>

      <Box sx={{ display: 'block', gap: '10px', p: 3 }}>
        <Stack spacing={2} sx={{ mb: 2 }}>
          <Typography
            level='title-sm'
            sx={{ color: '#CED4DA', fontSize: '16px', fontWeight: 400 }}
          >{`${t('dashboard_statistics_total_created')}`}</Typography>
          <Typography level="h3" sx={{ color: '#F1F1F1', fontSize: '20px', fontWeight: 600 }}>
            { otherStats?.total_created || '0' }
          </Typography>
        </Stack>
        <Divider />

        <Stack spacing={2} sx={{ mb: 2 }}>
          <Typography
            level='title-sm'
            sx={{ color: '#CED4DA', fontSize: '16px', fontWeight: 400 }}
          >{`${t('dashboard_statistics_total_failed')}`}</Typography>
          <Typography level="h3" sx={{ color: '#F1F1F1', fontSize: '20px', fontWeight: 600 }}>
            { otherStats?.total_failed || '0' }
          </Typography>
        </Stack>
        <Divider />

        <Stack spacing={2} sx={{ mb: 2 }}>
          <Typography
            level='title-sm'
            sx={{ color: '#CED4DA', fontSize: '16px', fontWeight: 400 }}
          >{`${t('dashboard_statistics_total_attempts')}`}</Typography>
          <Typography level="h3" sx={{ color: '#F1F1F1', fontSize: '20px', fontWeight: 600 }}>
            { otherStats?.total_attempts || '0' }
          </Typography>
        </Stack>
        <Divider />

        <Stack spacing={2} sx={{ mb: 2 }}>
          <Typography
            level='title-sm'
            sx={{ color: '#CED4DA', fontSize: '16px', fontWeight: 400 }}
          >{`${t('dashboard_statistics_total_created_paid')}`}</Typography>
          <Typography level="h3" sx={{ color: '#F1F1F1', fontSize: '20px', fontWeight: 600 }}>
            { otherStats?.total_created_paid || '0' }
          </Typography>
        </Stack>
        <Divider />

        <Stack spacing={2} sx={{ mb: 2 }}>
          <Typography
            level='title-sm'
            sx={{ color: '#CED4DA', fontSize: '16px', fontWeight: 400 }}
          >{`${t('dashboard_statistics_total_created_expired')}`}</Typography>
          <Typography level="h3" sx={{ color: '#F1F1F1', fontSize: '20px', fontWeight: 600 }}>
            { otherStats?.total_created_expired || '0' }
          </Typography>
        </Stack>
        <Divider />

        <Stack spacing={2} sx={{ mb: 2 }}>
          <Typography
            level='title-sm'
            sx={{ color: '#CED4DA', fontSize: '16px', fontWeight: 400 }}
          >{`${t('dashboard_statistics_avg_creating_time_ms')}`}</Typography>
          <Typography level="h3" sx={{ color: '#F1F1F1', fontSize: '20px', fontWeight: 600 }}>
            { Number(otherStats?.avg_creating_time_min).toFixed(2) || '0' }
          </Typography>
        </Stack>

        <Divider />
        <Stack spacing={2} sx={{ mb: 2 }}>
          <Typography
            level='title-sm'
            sx={{ color: '#CED4DA', fontSize: '16px', fontWeight: 400 }}
          >{`${t('dashboard_statistics_avg_closing_time_ms')}`}</Typography>
          <Typography level="h3" sx={{ color: '#F1F1F1', fontSize: '20px', fontWeight: 600 }}>
            { Number(otherStats?.avg_closing_disputes_min).toFixed(2) || '0' }
          </Typography>
        </Stack>

      </Box>
    </Box>
  );
};

export default Statistics;
