import React, { Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import GlobalStyles from '@mui/joy/GlobalStyles';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import NotFound from './pages/NotFound';
import PrivateRoute from './components/PrivateRoute';
import SignIn from './pages/SignIn';
import Home from './pages/Home';
import SignUp from './pages/SignUp';
import ForgotPassword from './pages/ForgotPassword';
import GoogleAuth from './pages/GoogleAuth';
import SignInKey from './pages/SignInKey';
import NewPassword from './pages/NewPassword';
import Transactions from './pages/Transactions';
import Settings from './pages/Settings';
import { useSelector } from 'react-redux';
import { getGlobalUser } from './redux/auth/selectors';
import { socket } from './socket';
import Dashboard from './pages/Dashboard';
import Withdrawals from './pages/Withdrawals';
import TransactionsQac from './pages/transactions-qac';
import Payouts from './pages/Payouts';

function App() {
  const { isLoggedIn, access_token } = useSelector(getGlobalUser);

  if (isLoggedIn) {
    socket.io.opts.extraHeaders = { Authorization: `Bearer ${access_token}`};
    socket.connect();
  }
  return (
    <>
      <CssVarsProvider disableTransitionOnChange defaultMode="dark">
        <CssBaseline />
        <GlobalStyles
          styles={{
            ':root': {
              '--Collapsed-breakpoint': '769px',
              '--Cover-width': '40vw',
              '--Form-maxWidth': '700px',
              '--Transition-duration': '0.4s',
            },
          }}
        />
        <ToastContainer autoClose={3000} />
        <Suspense fallback={<div>Loading...</div>}>
          <BrowserRouter>
            <Routes>
              <Route path="*" element={<NotFound />} />
              <Route path="/" element={<PrivateRoute />}>
                <Route path="/" element={<Home />}>
                  <Route path="/" index element={<Navigate replace to="dashboard" />} />
                  <Route path="transactions" element={<Transactions />} />
                  <Route path="transactions-qac" element={<TransactionsQac />} />
                  <Route path="payout-history" element={<Payouts />} />
                  <Route path="withdraw-history" element={<Withdrawals />} />
                  <Route path="settings" element={<Settings />} />
                  <Route path="dashboard" element={<Dashboard />} />
                </Route>
              </Route>
              <Route path="login" element={<SignIn />} />
              <Route path="login-key" element={<SignInKey />} />
              <Route path="confirm-login" element={<GoogleAuth />} />
              <Route path="registration" element={<SignUp />} />
              <Route path="forgot-password/" element={<ForgotPassword />} />
              <Route path="reset-password/:token" element={<NewPassword />} />
            </Routes>
          </BrowserRouter>
        </Suspense>
      </CssVarsProvider>
    </>
  );
}

export default App;
