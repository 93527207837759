import { Box, Button, Typography } from '@mui/joy';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../../utils/dateFormater';
import { getPayoutStatusTextColor } from '../../utils/getStatusColor';
import { Payouts } from '../../utils/types';
import HistoryPayoutModal from './HistoryPayoutModal';

const HistoryItemPayout = ({
  payout,
}:
{
  payout: Payouts;
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const toggleDrawer = (inOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setOpen(inOpen);
  };
  return (
    <>
      <tr key={payout.id}>
        <td>
          <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', color: '#F1F1F1' }}>
            <Typography level="body-md" sx={{ fontWeight: 600 }}>
              {parseFloat(payout.amount.toString()).toFixed(2)}
            </Typography>
          </Box>
        </td>
        <td>
          <Typography level="body-md" sx={{color:'#F1F1F1'}}>{Number(payout.merchant_amount).toFixed(2)}</Typography>
        </td>
        <td>
          <Typography level="body-md" sx={{color:'#F1F1F1'}}>{formatDateTime(payout.created_at)}</Typography>
        </td>
        <td>
          <Typography level="body-md" sx={{color:'#F1F1F1'}}>{formatDateTime(payout.updated_at)}</Typography>
        </td>
        <td>
          <Typography
            level="body-md"
            sx={{
              color: getPayoutStatusTextColor(payout?.status),
              fontWeight: 500,
              overflowWrap: 'anywhere',
            }}
          >
            {payout?.status &&
              payout.status.charAt(0).toUpperCase() +
              payout.status.slice(1).toLowerCase()}
          </Typography>
        </td>
        <td>
          <Button
            variant="plain"
            sx={{
              display: 'block',
              m: 0,
              borderBottom: '1px solid #947EFE',
              color: '#947EFE',
              borderRadius: 0,
              p: 0,
            }}
            onClick={toggleDrawer(true)}
          >
            {t('history_table_details')}
          </Button>
        </td>
      </tr>
      <HistoryPayoutModal
        id={payout.id.toString()}
        open={open}
        onClose={toggleDrawer(false)}
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      />
    </>
  );
};
export default HistoryItemPayout;
